.template-info-block {
   h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 2rem;
      
      // text-transform: lowercase;
   }

   h4 {
      font-size: 1rem;
      font-weight: 600;
   }

   ul {
      margin-bottom: 2rem;
   }

   .infoblock-container {
      .col-6 {
         width: 100%;
         margin-bottom: 1rem;

         div {
            border-radius: 1rem;
            background-color: $schedule-1;
            color: $white;
            padding: .8rem 1rem .5rem;

            p {
               &:first-child {
                  font-weight: bold;
                  font-size: 1rem;
                  border-bottom: 1px solid $white;
               }

               &:nth-child(2) {
                  font-size: .9rem;
               }

               &:nth-child(3),
               &:nth-child(4) {
                  margin: 0;
                  font-size: .9rem;
                  font-weight: bold;

                  @include media-breakpoint-up(md) {
                     font-size: 1.05rem;
                  }

                  @include media-breakpoint-up(lg) {
                     font-size: 1rem;
                  }

                  @include media-breakpoint-up(xl) {
                     font-size: 1.25rem;
                  }
               }
            }
         }

         @media screen and (width > 400px) {
            width: 50%;
            margin-bottom: 0;
         }

         @include media-breakpoint-up(md) {
            width: 100%;
            margin-bottom: 1rem;
         }

         @include media-breakpoint-up(lg) {
            width: 50%;
            margin-bottom: 0;
         }
      }

      &.mod-block-1 .col-6 {
         div {
            background-color: $schedule-3;
         }
      }

      &.mod-block-0 .col-6:nth-child(2) div {
         background-color: $schedule-2;
      }

      &.mod-block-1 .col-6:nth-child(2) div {
         background-color: $schedule-4;
      }

   }
}

.row.info-media:nth-child(even) {
   @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
   }
}