/*
#container-bzuco {
   visibility: hidden;
   position: absolute;
   top: 77px;
   right: 0;
   z-index: 10000;
   width: 100%;
   max-width: 1020px;
   background-color: white;
   border-bottom: 1px solid rgba($black, 0.05);
   left: 1px solid rgba($black, 0.05);
   box-shadow: 0 5px 50px rgba($black, 0.05);

   #bzuco-app-insert {
      padding: .5rem .8rem;
   }
}

.page-home #container-bzuco {
   top: 125px;
}
*/

#btn-bzuco-basket {
   @include media-breakpoint-up(lg) {
      min-width: 225px;
   }
}

.bzuco-widget-add-to-basket {
   .bzuco-widget-ticket {
      display: flex;
      flex-wrap: wrap;

      span {
         font-size: .85rem;
         font-weight: bold;
         display: block;
         width: 100%;
      }

      input {
         border: 1px solid $primary-darker;
         background-color: $gray-100;
         border-radius: 4px 0 0 4px;
         display: block;
         width: 25%;
         text-align: center;
         line-height: 2.2rem;
         font-weight: bold;
      }

      button {
         border: 1px solid $primary-darker;
         background-color: $primary;
         color: $gray-900;

         // text-shadow: 0 1px 1px rgba(0 0 0 / 10%);
         border-radius: 0 4px 4px 0;
         display: block;
         width: 75%;
         line-height: 2.2rem;
         text-transform: lowercase;
         font-weight: bold;
         font-size: .9rem;

         @include media-breakpoint-up(lg) {
            font-size: 1rem;   
         }

         &:hover {
            background-color: $primary-darker;
         }
      }
   }
}

/* stylelint-disable */
.basket__totals > tr:last-child {
   @media screen and (max-width: 569px) { 
      display: flex!important;
      justify-content: flex-start!important;

      > td {
         display: flex!important;
         align-items: center!important;
      
         &.empty {
            display: none!important;
         }

      }
   }
}