.hero {
   position: relative;
   z-index: 1;
   height: 100vh;
   min-height: 720px;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   background: top center no-repeat linear-gradient(9deg, rgb(178 178 178) 0%, 6.6890%, rgb(87 87 87) 13.3779%, 15.4961%, rgb(143 143 143) 17.6143%, 30.8807%, rgb(77 77 77) 44.1472%, 47.2129%, rgb(243 188 147) 50.2787%, 56.5775%, rgb(169 148 147) 62.8763%, 71.4047%, rgb(173 209 245) 79.9331%, 89.9666%, rgb(58 25 6) 100%);
   background-size: cover;

   &.mod-2024-festival {
      background: top center no-repeat linear-gradient(9deg, rgb(40 17 9) 0%, 6.6890%, rgb(174 66 4) 13.3779%, 15.4961%, rgb(202 110 59) 17.6143%, 30.8807%, rgb(237 156 77) 44.1472%, 47.2129%, rgb(243 188 147) 50.2787%, 56.5775%, rgb(169 148 147) 62.8763%, 71.4047%, rgb(173 209 245) 79.9331%, 89.9666%, rgb(58 25 6) 100%);
   }

   &.mod-2024-tour {
      background: linear-gradient(181deg, rgb(0 33 87) 0%, 12.0216%, rgb(2 50 122) 24.0432%, 31.7223%, rgb(4 79 160) 39.4014%, 44.4185%, rgb(26 107 189) 49.4357%, 52.343%, rgb(17 119 201) 55.2502%, 59.3965%, rgb(55 152 220) 63.5427%, 74.5093%, rgb(226 231 237) 85.476%, 92.738%, rgb(224 231 241) 100%);
   }

   .hero-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent top center no-repeat;
      background-size: cover;

      .container {
         position: relative;
         height: 100%;
      }

      .hero-title {
         position: absolute;
         top: 20%;
         width: calc(100% - 1.5rem);
         left: 3.4%;
         z-index: 3;
         margin: 0;

         @media screen and (width > 400px) {
            top: 28%;
         }

         img {
            width: 70%;
            height: auto;
            margin-bottom: 1rem;
            filter: drop-shadow(1px 1px 1px rgba(0 0 0 / 60%));

            @media screen and (width > 400px) {
               width: 80%;
               max-width: 300px;
               height: auto;
            }

            @media screen and (width > 991px) {
               width: 100%;
               max-width: 334px;
            }
            
         }

         h1 {
            color: $white;
            font-size: calc(1.1rem + 4vw);
            font-weight: 700;
            max-width: 265px;
            text-shadow: 2px 2px 8px rgba(0 0 0 / 60%);


            @media screen and (width > 320px) {
               font-size: calc(1.3rem + 4vw);
            }

            @media screen and (width > 400px) {
               font-size: 2.25rem;
            }

            @media screen and (height > 800px) and (width > 767px) {
               font-size: 2.75rem;
               text-shadow: 2px 2px 8px rgba(0 0 0 / 40%);
            }
   
            @media screen and (height > 900px) and (width > 991px) {
               text-shadow: 1px 1px rgba(0 0 0 / 10%);
               font-size: 3rem;
               max-width: 400px;
            }
         }
      }

      .hero-date {
         position: absolute;
         display: flex;
         align-items: center;
         top: 60%;
         width: calc(100% - 3rem);
         left: 3.4%;
         z-index: 4;
         margin: 0;
         text-transform: uppercase;
         text-align: left;
         font-size: 1.75rem;
         font-weight: 700;
         color: $white;
         text-shadow: 2px 2px 8px rgba(0 0 0 / 60%);

         @media screen and (width > 350px) {
            top: 65%;
         }

         @media screen and (width > 400px) {
            top: 75%;
         }

         @media screen and (width > 500px) {
            top: 70%;
         }

         @media screen and (width > 500px) {
            top: 80%;
         }

         @media screen and (height > 800px) and (width > 767px) {
            font-size: 2rem;
            text-shadow: 2px 2px 8px rgba(0 0 0 / 40%);
         }
   
         @media screen and (height > 900px) and (width > 991px) {
            text-shadow: 1px 1px rgba(0 0 0 / 10%);
            font-size: 2.5rem;
         }

         &::after {
            width: 40%;
            margin-left: 3.8%;
            height: 1px;
            border-bottom: 2px solid rgba(255 255 255 / 90%);
            display: none;
            content: "";

            @media screen and (width > 450px) {
               width: 40%;
               display: block;
            }

            @include media-breakpoint-up(lg) {
               width: 60%;
            }
         }
      }

      .hero-social {
         position: absolute;
         display: flex;
         flex-direction: column;
         align-items: center;
         background: transparent;
         top: 12vh;
         height: 60vh;
         bottom: 20vh;
         width: auto;
         right: 0;
         z-index: 5;
         margin: 0;
         font-size: 2.5rem;
         color: $white;
         text-shadow: 1px 1px rgba(0 0 0 / 10%);

         div {
            display: flex;
            flex-direction: column;

            a {
               color: $white;

               i { font-size: 2.1rem; }

               // line-height: 40px;

               // height: 40px;
               margin: 10px 0 0;
               text-decoration: none;

            }
         }

         &::before {
            height: 50%;
            width: 1px;
            border-left: 2px solid rgba(255 255 255 / 90%);
            display: block;
            content: "";
         }

         &::after {
            height: 30%;
            width: 1px;
            border-left: 2px solid rgba(255 255 255 / 90%);
            display: block;
            content: "";
         }
      }
   }
}