#content {
   position: absolute;
   z-index: 1000;
   top: 100vh;
   background: $white;
   height: auto;
   width: 100%;
   min-width: 320px;

   @media screen and (height < 720px) {
      top: 720px!important;
   }
}

#page-content {
   position: absolute;
   z-index: 1000;
   top: 80px;
   background: $white;
   min-height: calc( 100vh - 160px );
   height: auto;
   width: 100%;
   min-width: 320px;

   @include media-breakpoint-up(lg) { top: 100px; }

   @include media-breakpoint-up(xl) { top: 120px; }

   > .container {
      min-height: 20vh;
   }

   &.mod-banner {
      top: 124px;

      @include media-breakpoint-up(lg) { top: 144px; }

      @include media-breakpoint-up(xl) { top: 164px; }
   }
}

@include media-breakpoint-up(md) {
   .offset-md-1-5 {
      margin-left: 12.5%;
   }
}

.mb-section {
   margin-bottom: 2rem;

   @include media-breakpoint-up(sm) { margin-bottom: 2.5rem; }

   @include media-breakpoint-up(md) { margin-bottom: 3rem; }

   @include media-breakpoint-up(lg) { margin-bottom: 5rem; }

   @include media-breakpoint-up(xl) { margin-bottom: 7rem; }
   
}

@include media-breakpoint-up(md) {
   .mb-md-4-5 {
      margin-bottom: 2rem!important;
   }
}

.btn-admin-edit {
   position: absolute;
   top: 0;
   right: -26px;
   display: block;
   width: 24px;
   height: 24px;
   background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAdgAAAHYBTnsmCAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFbSURBVDiNldG9L0NhFMfx7/Pcy1UNaQwIidgkJDYhmlikxFu9Lp0MYrPyB5j4BwwmG60IgpK0mxgwSHQQMbCIEiFab21v72MprRJuz3w+v+ec8wgKLDU84wG5jGKJjblpURBeWPMSOl4BHADcO/2abfwQ7KG9f520ZXB2BUkNHo0aWwEq4vYQP9igqNygzYtp6UqGogksOfJvgIq4PSg2QTmIH5LGRbSxy5J3zz4jMLHzZ0AWZ3Y2HUpeRJLpsspB10jvFoBuGyc1uNUTpBioqO8Of/b9+gsZvA0UZ3HpO6b0isFgKLdX/vHyd5zSBvLxjwl+HzuDh3bC+fhbgDpt7wARBJx28dcK6nysE8ReoTh7g6rJeYrrXgAw9YRdDCDUU7gFJY9QJtysWlxem6REnx0MoMVE9Wxbc21TydtLjFfXOYn4lOjz79rBADS09uyP+0YD6mSxxTbKqQ8EEp7DvQe+uQAAAABJRU5ErkJggg==');
   background-size: 16px 16px;
   background-position: center;
   background-repeat: no-repeat;
}

.badge.text-bg-success {
   background: linear-gradient(to right, #009245, #45B649);
}

.sticky-left {
   position: sticky;
   width: 100%;
}

.fg-2 {
   flex-grow: 2;
}

.flex-grow { flex-grow: 1; }