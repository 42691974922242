/* latin-ext */
@font-face {
  font-family: Raleway;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/raleway/v34/1Ptsg8zYS_SKggPNyCg4Q4FqPfE.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Raleway;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/raleway/v34/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/raleway/v34/1Ptug8zYS_SKggPNyCMIT5lu.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/raleway/v34/1Ptug8zYS_SKggPNyC0ITw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


body {
  font-family: Raleway, sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  min-width: 320px;
}

h1 {
  font-size: 2rem;
  font-weight: 800;

  @include media-breakpoint-up(sm) { font-size: 2.4rem; }
  
  @include media-breakpoint-up(md) { margin-bottom: 2.6rem; }
  
  @include media-breakpoint-up(lg) { font-size: 2.8rem; }
}

h2 {
  font-weight: 800;
}

.lh-1 {
  line-height: 1;
}

