.award {
   &-box {
      p:last-child {
         margin-bottom: 0;
      }
   }

   &-hp {
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      .award-item {
         // flex-grow: 1;
         text-align: center;
         padding: 1rem .5rem;
         display: flex;
         flex-direction: column;
         align-items: center;
         font-size: .85rem;
         width: 50%;
         justify-content: space-between;

         img {
            margin-bottom: 1rem;
         }

         .award-item-title {
            color: $primary;
            font-size: 1rem;
            font-weight: 800;
            margin-bottom: .6rem;
         }

         .event-item {
            width: 100%;
         }

         @include media-breakpoint-up(sm) {
            width: 33%;
         }

         @include media-breakpoint-up(md) {
            width: 25%;
         }

         @include media-breakpoint-up(lg) {
            width: 20%;
            padding: 0 1rem 4rem;
         }


      }
   }
}