.event-item {
   position: relative;
   aspect-ratio: 10/16;
   overflow: hidden;

   &.mod-tourstop {
      aspect-ratio: 3/4;
   }

   &::before {
      width: 100%;
      padding-top: 160%;
      display: block;
      position: absolute;
      inset: 0;
      content: '';
      background: transparent;
   }

   a {
      display: block;
      position: absolute;
      inset: 0;

      img {
         width: 100%;
         height: auto;
         transition: .5s all;
      }

      span {
         position: absolute;
         right: 0;
         max-width: 80%;
         bottom: 1rem;
         background-color: $primary;
         color: $gray-900;
         padding: 0.6rem 1rem 0.5rem;
         text-decoration: none;
         transition: .5s all;
         display: flex;
         justify-content: space-between;
         align-items: center;

         i {
            font-size: .9rem;
            margin-left: 0.5rem;
            line-height: 1rem;
         }
      }

      .award-stamp {
         position: absolute;
         top: 2px;
         right: 0;
         max-width: 125px;

         img {
            filter: drop-shadow(0 0 3px $gray-900);
         }
      }

      &:hover,
      &:focus {
         margin: 0;

         > img {
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-top: -10px;
         }

         span {
            background-color: $primary-darker;
            text-overflow: unset;
            overflow: auto;
            white-space: normal;
         }
      }
   }
}