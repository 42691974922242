@use "sass:color";

.section-info {
   background-color: $gray-200;
   color: $gray-900;

   &.mod-pattern {
      background: url('../../vendors/images/2024-bg-pattern.png?v=2') top left repeat;

      a {
         font-weight: bold;
      }
   }

   .container,
   .container-lg,
   .container-xl,
   .container-xxl {
      padding-top: 3rem;
      padding-bottom: 1.5rem;
   }

   .splide-item {
      h3 {
         font-size: 1rem;
         font-weight: bold;
         color: color.adjust($primary, $lightness: -30%);
      }

      h4 {
         font-size: .85rem;
         font-weight: bold;
         color: $gray-600;
      }

      p {
         font-size: .85rem;
      }

      &-text {
         font-size: .85rem;

         ul {
            list-style: disc;
            padding-left: 16px;
         }

         h4 {
            color: $gray-900;
         }

         .infoblock-container .col-6 div p:nth-child(3),
         .infoblock-container .col-6 div p:nth-child(4) {
            font-size: .95rem;
         }
      }
   }
}