footer {
   position: relative;
   padding: 3rem 0;
   color: $white;
   font-size: 14px;
   background: rgba(0 0 0 / 90%) top center no-repeat;
   background-size: cover;

   &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0 0 0 / 50%);
   }

   .container {
      position: relative;
   }

   .footer-brand {
      img {
         max-height: 80px;
         width: auto;
      }
   }

   .row:last-child {
      font-size: 12px;
   }

   a {
      color: $white;
      text-decoration: none;
      font-weight: 800;

      &:hover,
      &:focus {
         text-decoration: underline!important;
      }
   }
}
