.schedule {
   max-width: 100%;

   @include media-breakpoint-up(xxxl) {
      max-width: 96%;
   }
}

.schedule-date-separator {
   position: sticky;
   z-index: 9;
   top: 252px;
   padding: 3rem 0 2rem;
   background-color: $white;
   display: flex;
   justify-content: center;

   &::before {
      display: block;
      border-bottom: 1px solid $border-color;
      content: "";
      width: 100%;
      position: absolute;
      top: 3.8rem;
      z-index: -1;
   }

   span {
      display: inline-block;
      margin: 0 auto -.8rem;
      background-color: $border-color;
      color: $gray-700;
      font-weight: 800;
      padding: .15rem .5rem .25rem;
      font-size: .85rem;
      text-align: center;
      border-radius: 0.4rem;
   }
}

.schedule-container {
   align-items: stretch;
   justify-content: space-evenly;
   min-width: 1200px;


   &.mod-header {
      position: sticky;
      top: 76px;
      z-index: 10;
      background-color: $white;
      box-shadow: 0 10px 8px rgba(255 255 255 / 100%);
      padding: 2rem 0 0;
   }

   &.mod-contentt {
      position: sticky;
      top: 276px;
      z-index: 10;
   }

   &-inner {
      margin: 0!important;
      justify-content: center;
      background: $white;
   }
}

.mod-banner {
   .schedule-container.mod-header {
      top: 120px;
   }

   .schedule-date-separator {
      top: 296px;
   }
}
.schedule-block {
   position: relative;
   margin-bottom: 0.8rem;
   border-radius: 34px 0 0;

   &-title {
      padding: 0.45rem 0.5rem 0.45rem 1.5rem;
      border-radius: 34px 0 0;
      background-color: $border-color;
      font-weight: bold;
      font-size: .85rem;
      display: flex;
      justify-content: space-between;

      span {
         font-size: .85rem;
         margin-right: 1rem;
         font-weight: 400;

         &.schedule-block-price {
            margin-left: 1rem;
            margin-right: 0;
         }
      }
   }

   &--event-container {
      padding: .4rem .8rem .8rem;
   }

   // &:hover {
   //   background-color: $gray-200;
   // }
}

.schedule-event {
   position: relative;

   &--title {
      font-size: .95rem;
      font-weight: 800;
      margin-bottom: 0;
   }

   &--description {
      font-size: .8rem;
   }

   &.mod-highlight {
      border: 1px solid $gray-400;
      border-radius: $border-radius;
      padding: .4rem;
      margin: -.4rem -.4rem 1rem;

      p {
         margin-bottom: 0;
      }
   }

   a {
      color: $gray-900;
      text-decoration: none;

      &:hover,
      &:active {
         color: $black;
         text-decoration: underline;
      }
   }

   img {
      max-width: 100%;
      height: auto;
      margin: 0 0 .4rem;
      border-radius: $border-radius;
   }
}

.schedule-area {
   padding: 0 1rem;
   max-width: 534px;

   &--title-container {
      margin-bottom: 0.4rem;
      height: 3.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   &--title {
      margin-bottom: 0;
      font-size: 1.25rem;
   }

   &.mod-header {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
   }

   .schedule-block-title {
      &.mod-area-cinema {
         background-color: $schedule-1;
         color: $white;
      }

      &.mod-area-farmhouse {
         background-color: $schedule-2;
         color: $white;
      }

      &.mod-area-amphitheater {
         background-color: $schedule-3;
         color: $white;
      }

      &.mod-area-park,
      &.mod-area-park-boulder {
         background-color: $schedule-4;
         color: $white;
      }

      &.mod-area-talk-show-stage-exhibition {
         background-color: $schedule-5;
         color: $white;
      }

      &.mod-area-boulder,
      &.mod-area-park-doprovod {
         background-color: $schedule-6;
         color: $white;
      }
   }

}

.areas-count-4 {
   .schedule-area {
      width: 25%;
   }
}

.areas-count-5 {
   min-width: 1250px;

   .schedule-area {
      width: 20%;
   }
}

.areas-count-6 {
   min-width: 1500px;

   .schedule-area {
      width: 16.6666%;

      @include media-breakpoint-down(lg) {
         padding: 0 8px;
      }
   }
}

@include media-breakpoint-down(xl) {
   .schedule-list #page-content,
   .schedule-container.mod-header {
      top: 61px;
   }

   .schedule-date-separator {
      top: 238px;
   }
}

@include media-breakpoint-down(lg) {
   .schedule-list #page-content {
      top: 61px;
   }

   .schedule {
      overflow: visible;
      padding: 0 8px;
   }

   .schedule-container.mod-header {
      position: relative;
      top: 0;
      padding-top: 0;
   }

   .schedule-area {
      &--title {
         font-size: 1.125rem;
      }
   }

   // .bzuco-widget-add-to-basket .bzuco-widget-ticket span { visibility: hidden; }

   .schedule-date-separator {
      position: relative;
      top: 0;
   }
}

.schedule-filter {
   position: relative;
   z-index: 10000;

   .btn {
      position: relative;
      background-color: $border-color;
      color: $gray-700;
      font-weight: 800;
      font-size: .85rem;
      line-height: 1.5;
      padding-left: 34px;

      i {
         position: absolute;
         left: 12px;
         top: 4px;
         font-weight: 300;;
      }
   }
}

.js-mobile .container-fluid.schedule {
   overflow: auto!important;
}


.schedule-column {
   .schedule-container {
      min-width: auto;
   }

   .bzuco-widget-add-to-basket {
      max-width: 950px;
   }

   .schedule-filter {
      padding: 0;
      max-width: 950px;
   }

   .schedule-container-inner {
      flex-direction: column;
      align-content: center;
   }

   .schedule-date-separator {
      position: sticky;
      top: 23px;

      @include media-breakpoint-up(lg) {
         top: 55px;
      }
   }

   .schedule-area {
      max-width: 950px;
      padding: 0;

      .schedule-block-title {
         align-items: center;
         flex-direction: column;

         > div {
            display: flex;
            margin-top: .3rem;
            width: 100%;
            justify-content: space-between;
         }

         .schedule-block-place {
            margin-right: 1rem;

            span {
               margin-right: 0;
               margin-left: .5rem;
               max-width: 80px;
               font-weight: 400;
            }
         }
      }
   }

   .schedule-event img {
      width: 100%;
   }
}