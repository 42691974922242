.banner-top {
   height: 44px;
   position: fixed;
   width: 100%;
   top: 0;
   left: 0;
   right: 0;
   background: #ddd;



   &.mod-2025 {
      color: #d23d2d;
      background-color: #fcd62b;

      a {
         color: #d23d2d;
         text-decoration: none;
         font-size: .75rem;
         font-weight: bold;
         padding-left: 1.25rem;

         &:hover {
            text-decoration: underline;
         }
      }
   }

   .container-lg {
      @media screen and (width > 319px) {
         display: flex;
         align-items: center;
         justify-content: center;
      }

      a { display: none; }

      @media screen and (width > 530px) {
         justify-content: flex-end;
         gap: 1.25rem;

         a { display: inline-block; }
      }
   }

   img {
      height: 100%;
      max-height: 44px;
      width: auto;
      min-width: 320px;
   }
}