.mod-tourstop-past {
   opacity: .5;
   transition: opacity ease-in 300ms;

   img {
      filter: grayscale(90%);
      transition: filter ease-in 200ms;
   }

   &:hover {
      opacity: 1;

      img {
         filter: grayscale(0);
      }
   }
}