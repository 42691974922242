.schedule-list #page-content {
   top: 76px;

   &.mod-banner {
      top: 120px;
   }
}


@media screen and (width > 200px) {
  .animate {
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation-name: slide-in;
}

.navbar.navbar-main {
   padding-top: 1.5rem;
   transition: .5s all;
   min-width: 320px;

   &.mod-banner {
      margin-top: 44px;
   }

   .navbar-brand {
      padding: 0;

      img {
         width: auto;
         transition: .5s all;
         max-height: 34px;

         
         @media screen and (width > 370px) { max-height: 40px; }

         @include media-breakpoint-up(lg) { max-height: 50px; }

         @include media-breakpoint-up(xl) { max-height: 60px; }

         @include media-breakpoint-up(xxl) { max-height: 70px; }

         &.mod-dark {
            display: none;
         }

         &.mod-light {
            display: inline;
         }
      }
   }

   // .navbar-nav {
   //    margin-right: 60px;
   // }

   @include media-breakpoint-up(lg) {
      .nav-item {
         margin-left: 1rem;

         &:first-child {
            margin-left: 0
         }
      }
   }

   .nav-link {
      font-size: .85rem;
      font-weight: 800;
      padding: 0.5rem 1rem;
      color: $white;
      text-shadow: 1px 1px 1px rgba(0 0 0 / 10%);
      text-transform: uppercase;
      transition: .5s all;
      border-bottom: 2px solid transparent;

      @include media-breakpoint-up(lg) { font-size: .875rem!important; }

      @include media-breakpoint-up(xl) { font-size: .9375rem!important; }

      @include media-breakpoint-up(xxl) { font-size: 1.1rem!important; }

      @include media-breakpoint-up(xxxl) { font-size: 1.2rem!important; }

      &:hover {
         border-bottom-color: $white;
      }

      &.mod-highlight {
         background-color: $primary;
         color: $gray-900;
         text-shadow: none;
         border-bottom: 0 none;
         border-radius: 4px;

         &:hover {
            background-color: $primary-darker;
         }

         &.is-not-empty {
            background: linear-gradient(to right, #009245, #45B649);
            color: $white;
         }
      }

      &.active {
         color: $primary!important;
         border-color: transparent !important;
      }

      @include media-breakpoint-up(lg) {
         font-size: 1rem;
      }

      @include media-breakpoint-up(xl) {
         font-size: 1.2rem;
      }
   }

   .dropdown-menu {
      position: absolute;
      width: 100%;
      border: 0;
      top: 40px;
      background-color: transparent;
      border-radius: 0 0 4px 4px;

      &.show {
         background-color: $white;
      }

      .dropdown-item {
         color: $gray-900;
         background-color: transparent;

         &:hover {
            background-color: $gray-200;
         }
      }
   }

   .navbar-toggler {
      border: 1px solid rgba(30 30 30 / 30%);
      color: $black;
      background-color: rgba(255 255 255 / 90%);
      font-weight: 800;
      font-size: .85rem;

      &[aria-expanded="true"] .navbar-toggler-icon {
         --#{$prefix}navbar-toggler-icon-bg-active: #{escape-svg($navbar-dark-toggler-icon-bg-active)};

         background-image: var(--#{$prefix}navbar-toggler-icon-bg-active);
      }

      span {
         font-size: 1rem;
         margin-right: 5px;
      }

      &:hover {
         background-color: $primary;
         color: $black;
         border-color: $black;
         box-shadow: none;
      }

      &:focus {
         box-shadow: none;
      }
   }

   @include media-breakpoint-down(lg) {
      .navbar-collapse.show, .navbar-collapse.collapsing {
         padding-top: 1rem;
         margin-top: 8px;
         border-top: 1px solid $border-color;
      }
   }

   &.js-navbar-scrolled {
      background-color: rgba($white, 1);
      padding-top: 0.5rem;
      border-bottom: 1px solid rgba($black, 0.05);
      box-shadow: 0 5px 50px rgba($black, 0.05);

      .navbar-brand {
         img {
            width: auto;
            transition: .5s all;
            max-height: 34px;

            @media screen and (width > 370px) { max-height: 40px; }

            @include media-breakpoint-up(lg) { max-height: 50px; }

            @include media-breakpoint-up(xl) { max-height: 60px; }


            &.mod-dark {
               display: inline;
            }

            &.mod-light {
               display: none;
            }
         }
      }

      .nav-link {
         color: $black;

         @include media-breakpoint-down(lg) {
            line-height: 1.8rem;
         }

         &:hover {
            border-bottom-color: $black;
         }
      }

      .mod-social {
         @include media-breakpoint-down(lg) {
            a {
               text-decoration: none;
               padding: .5rem;
               font-size: 1.4rem;
               color: $gray-900;

               i {
                  font-size: 2.125rem;
               }
            }
         }
      }
   }

   .nav-item.dropdown {
      .nav-link {
         &.show {
            background-color: $white;
            color: $black;

            &:hover {
               border-bottom: 0 none;
            }
         }

         @include media-breakpoint-down(lg) {
            display: none;
         }
      }

      @include media-breakpoint-down(lg) {
            .dropdown-menu {
               position: relative;
               display: block;
               top: 0;
               font-size: .85rem;
               padding-top: 0;

               .dropdown-item {
                  text-transform: uppercase;
                  font-weight: 800;
                  line-height: 1.8rem;
                  border: 1px solid transparent;
                  padding: 0.5rem 1rem;

                  &:hover,
                  &:focus {
                     background: transparent;
                     border-bottom-color: $black;
                  }
               }
            }
   }
   }

   #btn-bzuco-basket-mobile {
      background: linear-gradient(to right, #009245, #45B649);
      color: $white;
      margin: 0 10px 0 auto;
      font-size: .85rem;
      line-height: 1;
      font-weight: 300;
      display: flex;
      align-items: center;
      padding: 8px 12px;


      i {
         // margin-right: 8px;
         display: inline-block;
      }
   }

   /* stylelint-disable */
   &.mod-tour {
      .navbar-brand img.mod-light {
          display: none;
      }
      .navbar-brand img.mod-dark {
          display: inline;
      }

      .nav-link {
         color: $gray-900;

         &:hover {
            border-bottom-color: $gray-900;
         }
      }
   }
}
