.stats {
   background: $white;
   padding: 0;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-evenly;

   .stats-item {
      flex-grow: 1;
      text-align: center;
      padding: 1rem .5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .85rem;
      width: 33.3333%;

      i {
         font-size: 2rem;
         rotate: 10deg;
         margin-bottom: 0;
      }

      .stats-counter {
         color: $primary;
         font-size: 2rem;
         font-weight: 800;
         margin-bottom: .6rem;
      }

      .stats-note {
         font-size: .85rem;
      }

      @include media-breakpoint-up(sm) {
         width: 30%;
      }

      @include media-breakpoint-up(lg) {
         width: 15%;
         padding: 4rem 1rem;

         i {
            font-size: 3.5rem;
            margin-bottom: .6rem;
         }
   
         .stats-counter {
            font-size: 3rem;
         }
   
         .stats-note {
            font-size: 1.2rem;
         }
      }

      
   }
}