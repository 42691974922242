// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red

$prefix: 'mhff-';
$primary: #bda842;
$primary-darker: #a49135;

// navbar
$navbar-light-icon-color: #fff !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M5 15H19M5 9H19' stroke='#{$navbar-light-icon-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;
$navbar-light-toggler-icon-bg-active: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16' stroke='#{$navbar-light-icon-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;
$navbar-dark-icon-color: #212529 !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M5 15H19M5 9H19' stroke='#{$navbar-dark-icon-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;
$navbar-dark-toggler-icon-bg-active: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16' stroke='#{$navbar-dark-icon-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;

// schedule colors
$schedule-1: #b35000;
$schedule-2: #800020;
$schedule-3: #036;
$schedule-4: #1b4d3e;
$schedule-5: #2e003e;
$schedule-6: #2b2b2b;

// scss-docs-start theme-color-variables
$primary: $primary !default;
$secondary: #6C757D !default;
$success: #198754 !default;
$info: #0DCAF0 !default;
$warning: #FFC107 !default;
$danger: #B83643 !default;
$light: #F8F9FA !default;
$dark: #212529 !default;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
) !default;

// Grid breakpoints
$grid-breakpoints: (
      xs: 0,
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px,
      xxl: 1400px,
      xxxl: 1500px,
) !default;
$container-max-widths: (
      sm: 540px,
      md: 720px,
      lg: 960px,
      xl: 1140px,
      xxl: 1320px,
      xxxl: 1440px
) !default;

@import "~bootstrap/scss/bootstrap";

// importing font awesome
// $fa-font-path: "../../vendors/fonts";

// @import "~@fortawesome/fontawesome-free/scss/fontawesome";
// @import "~@fortawesome/fontawesome-free/scss/solid";
// @import "~@fortawesome/fontawesome-free/scss/regular";
// @import "~@fortawesome/fontawesome-free/scss/brands";

// Default theme
@import '@splidejs/splide/dist/css/splide.min.css';

// toast
@import "toastify-js/src/toastify.css";

// or other themes
// import '@splidejs/splide/css/skyblue';
// import '@splidejs/splide/css/sea-green';

// or only core styles
// @import '~@splidejs/splide/css/core';

// import page style
@import "typo";
@import "layout";
@import "pages/homepage";
@import "pages/event-detail";
@import "pages/template-info";
@import "components/hero";
@import "components/navbar";
@import "components/footer";
@import "components/stats";
@import "components/guests";
@import "components/splide";
@import "components/info";
@import "components/schedule";
@import "components/partners";
@import "components/bzuco";
@import "components/event-list";
@import "components/btn";
@import "components/award";
@import "components/banner";
@import "components/tour";

// Default theme
$mhffi-font-path: "../fonts" !default;

@import './mhff-icons';

.btn-primary {
   color: $gray-900;
   background-color: $primary;
   font-weight: bold;
}

:root {
   --bzuco-color-primary: #bda842 !important;
}