.person-item {
   position: relative;
   aspect-ratio: 16/10;
   overflow: hidden;

   &::before {
      width: 100%;
      padding-top: 62.5%;
      display: block;
      position: absolute;
      inset: 0;
      content: '';
      background: transparent;
   }

   a {
      display: block;
      position: absolute;
      inset: 0;

      img {
         width: 100%;
         height: auto;
         transition: .5s all;
      }

      span {
         position: absolute;
         right: 0;
         max-width: 80%;
         bottom: 1rem;
         background-color: $primary;
         color: $gray-900;
         padding: 0.6rem 1rem 0.5rem;
         text-decoration: none;
         transition: .5s all;
         display: flex;
         justify-content: space-between;
         align-items: center;

         i {
            font-size: .9rem;
            margin-left: 0.5rem;
            line-height: 1rem;
         }
      }

      &:hover,
      &:focus {
         margin: 0;

         img {
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-top: -10px;
         }

         span {
            background-color: $primary-darker;
            text-overflow: unset;
            overflow: auto;
            white-space: normal;
         }
      }
   }
}

.guest-section {
   margin-bottom: 7rem;

   .col-md-3 {
      padding: 0;

      .person-item {
         &::after {
            content: "";
            display: block;
            position: absolute;
            z-index: 100;
            right: -1px;
            top: 10%;
            bottom: 10%;
            width: 2px;
            background: $white;
         }
      }

      &:last-child .person-item::after {
         display: none;
      }
   }
}

.guest-section-main {
   .container {
      margin-bottom: 1.5rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
   }

}

