.partners {
   .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
         flex-direction: row;
      }
   }

   h2 {
      font-size: .95rem;
      color: $gray-600;
   }

   .partners-partner,
   .partner-main {
      width: 100%;

      @include media-breakpoint-up(md) {
         width: 70%;
      }
   }

   .partner-general,
   .partners-media-partner {
      width: 100%;

      @include media-breakpoint-up(md) {
         width: 25%;
      }
   }

   .partners-inner {
      display: flex;
      justify-content: space-evenly;
      gap: 1.5rem;
      align-items: stretch;
      flex-wrap: wrap;

      @include media-breakpoint-up(sm) {
         flex-wrap: nowrap;
      }

      div {
         display: flex;
         align-items: center;
      }
   }
}