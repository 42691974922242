.splide {
   visibility: visible;
}

.splide.mod-tiny li {
   .splide-item {
      position: relative;

      &::after {
         content: "";
         display: block;
         position: absolute;
         z-index: 100;
         right: -1px;
         top: 10%;
         bottom: 10%;
         width: 2px;
         background: $white;
      }
   }

   a {
      position: absolute;
      right: 0;
      max-width: 80%;
      bottom: 1rem;
      background-color: $primary;
      color: $gray-900;
      padding: 0.6rem 1rem 0.5rem;
      text-decoration: none;
      transition: .5s all;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
         text-overflow: ellipsis;
         overflow: hidden;
         white-space: nowrap;
      }

      i {
         font-size: .9rem;
         margin-left: 0.5rem;
         line-height: 1rem;
      }

      &:hover,
      &:focus {
         background-color: $primary-darker;

         span {
            text-overflow: unset;
            overflow: auto;
            white-space: normal;
         }
      }
   }

   &:last-child .splide-item::after {
      display: none;
   }
}

/* stylelint-disable */
.splide__arrow {
   width: 3em;
   height: 3em;
}