@font-face {font-family: "mhff icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('../../vendors/fonts/mhff-icons.eot?t=1740072843323');
  src: url('../../vendors/fonts/mhff-icons.eot?t=1740072843323#iefix') format('embedded-opentype'),
  url("../../vendors/fonts/mhff-icons.woff2?t=1740072843323") format("woff2"),
  url("../../vendors/fonts/mhff-icons.woff?t=1740072843323") format("woff"),
  url('../../vendors/fonts/mhff-icons.ttf?t=1740072843323') format('truetype'),
  url('../../vendors/fonts/mhff-icons.svg?t=1740072843323#mhff-icons') format('svg');
}

[class^="mhffi-"], [class*=" mhffi-"] {
  font-family: 'mhff icons', sans-serif;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mhffi-block::before { content: "\ea01"; }
.mhffi-cart::before { content: "\ea02"; }
.mhffi-chevron-right::before { content: "\ea03"; }
.mhffi-column::before { content: "\ea04"; }
.mhffi-facebook::before { content: "\ea05"; }
.mhffi-filter::before { content: "\ea06"; }
.mhffi-guest::before { content: "\ea07"; }
.mhffi-instagram::before { content: "\ea08"; }
.mhffi-motion-picture::before { content: "\ea09"; }
.mhffi-movie::before { content: "\ea0a"; }
.mhffi-program::before { content: "\ea0b"; }
.mhffi-scene::before { content: "\ea0c"; }
.mhffi-talk::before { content: "\ea0d"; }
.mhffi-ticket::before { content: "\ea0e"; }
.mhffi-youtube::before { content: "\ea0f"; }

$mhffi-block: "\ea01";
$mhffi-cart: "\ea02";
$mhffi-chevron-right: "\ea03";
$mhffi-column: "\ea04";
$mhffi-facebook: "\ea05";
$mhffi-filter: "\ea06";
$mhffi-guest: "\ea07";
$mhffi-instagram: "\ea08";
$mhffi-motion-picture: "\ea09";
$mhffi-movie: "\ea0a";
$mhffi-program: "\ea0b";
$mhffi-scene: "\ea0c";
$mhffi-talk: "\ea0d";
$mhffi-ticket: "\ea0e";
$mhffi-youtube: "\ea0f";

